import axios from "axios"

function processResults(rows) {
    const result = {};

    rows.forEach(row => {
        const { month, status, count } = row;

        if (!result[month]) {
            result[month] = { data: month, statuses: [] };
        }

        result[month].statuses.push({ status, num: count });
    });

    return Object.values(result); // Convertendo objeto para array
}


class IndicationServices {


    async CreateIndication(userId, name, email, phone) {


        try {

            const response = await axios.post("https://cvd.horizontes.top/indication/create", {
                userId,
                name,
                email,
                phone
            })

            if (response.status == 200) {
                return { status: true }
            } else {
                return { status: false }
            }

        } catch (e) {

            console.log(e);

        }


    }



    async GetAllIndications() {

        try {

            const res = await axios.get("https://cvd.horizontes.top/indication");

            if (res.status == 200) {
                return { status: true, indications: res.data.indications }
            } else {
                return { status: false }
            }

        } catch (e) {
            return { status: false, error: e }
        }

    }

    async GetAllIndicationsById(userId) {

        try {

            const res = await axios.get("https://cvd.horizontes.top/indication/getByUserId/" + userId);

            if (res.status == 200) {
                return { status: true, indications: res.data.indications }
            } else {
                return { status: false }
            }

        } catch (e) {
            return { status: false, error: e }
        }

    }

    async getChartData() {
        try {

            const res = await axios.get("https://cvd.horizontes.top/indication/chartData");

            if (res.status == 200) {
                const data = processResults(res.data.data);
                return { status: true, data: data }
            } else {
                return { status: false }
            }

        } catch (e) {
            return { status: false, error: e }
        }
    }


    async UpdateStatus(id, indicatorPhone, status, negStepOne, negStepTwo, negStepThree, email, name, cep, rua, bairro, cidade, estado, numero, complemento) {
        try {

            const response = await axios.put("https://cvd.horizontes.top/indication/status/" + id, {
                status,
                negStepOne,
                negStepTwo,
                negStepThree,
                email,
                name,
                cep,
                rua,
                bairro,
                cidade,
                estado,
                numero,
                complemento,
                indicatorPhone
            })

            if (response.status == 200) {
                return { status: true }
            } else {
                return { status: false }
            }

        } catch (e) {

            console.log(e);
            return { status: false, msg: e }

        }
    }


}


export default IndicationServices;