import axios from "axios"



class RewardServices{


    async CreateReward(userId, title){

        
        try{

            const response = await axios.post("https://cvd.horizontes.top/reward/create", {
                userId,
                title,
            })

            if(response.status == 200){
                return {status: true}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);

        }


    }


    async GetAllRewards(){

        try{

            const res = await axios.get("https://cvd.horizontes.top/reward");

            console.log(res.data.rewards);

            if(res.status == 200){
                return {status: true, rewards: res.data.rewards}
            }else{
                return {status: false}
            }

        }catch(e){
            return {status: false, error: e}
        }

    }


    async UpdateStatus(id, status){
        
        try{

            const response = await axios.put("https://cvd.horizontes.top/reward/status/"+id, {
                status
            })

            if(response.status == 200){
                return {status: true}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);
            return {status: false, msg: e}

        }
    }


    async DeleteReward(id){
        try{

            const response = await axios.delete("https://cvd.horizontes.top/reward/"+id)

            if(response.status == 200){
                return {status: true}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);
            return {status: false, msg: e}

        }
    }


    async RequestReward(userId, rewardId, indicationId,title){

        
        try{

            const response = await axios.post("https://cvd.horizontes.top/reward/requestReward", {
                userId,
                title,
                indicationId,
                rewardId
            })

            if(response.status == 200){
                return {status: true}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);

        }


    }


    async GetAllRewardsRequest(){


        try{

            const res = await axios.get("https://cvd.horizontes.top/reward/rewardsRequest");


            if(res.status == 200){
                return {status: true, rewardsRequest: res.data.rewardsRequest}
            }else{
                return {status: false}
            }

        }catch(e){
            return {status: false, error: e}
        }

    }



    async AnnouncementReward(indicatorPhone, rewardId){

        
        try{

            const response = await axios.put("https://cvd.horizontes.top/reward/announcementReward/"+rewardId, {
                indicatorPhone
            })

            if(response.status == 200){
                return {status: true}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);

        }


    }

}


export default RewardServices;