import React from 'react';
import { Line } from 'react-chartjs-2';

const MyChart = ({ data }) => {

    const getStatusNum = (statuses, status) => {
        const statusData = statuses.find(item => item.status === status);
        return statusData ? statusData.num : 0;
      };

  const chartData = {
    labels: data.map(item => item.data),
    datasets: [
      {
        label: 'Aberto',
        data: data.map(item => getStatusNum(item.statuses, 0)),
        fill: false,
        borderColor: '#F2A735',
        tension: 0.1,
      },
      {
        label: 'Em negociação',
        data: data.map(item => getStatusNum(item.statuses, 1)),
        fill: false,
        borderColor: '#F4159B',
        tension: 0.1,
      },
      {
        label: 'Ganhou',
        data: data.map(item => getStatusNum(item.statuses, 2)),
        fill: false,
        borderColor: '#27A468',
        tension: 0.1,
      },
      {
        label: 'Perdeu',
        data: data.map(item => getStatusNum(item.statuses, 3)),
        fill: false,
        borderColor: 'red',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,  // Esta linha oculta a legenda
          },
      title: {
        display: true,
        text: 'Relatório',
      },
    },
  };



  return <Line options={options} data={chartData} />;
};

export default MyChart;