import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Stack, Checkbox, FormControlLabel, Collapse } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Check, Close, ExpandMore, Logout } from "@mui/icons-material";
import { Home } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import AddIcon from "../../assets/addIcon.svg"
import { NavLink } from "react-router-dom";
import { RewardItem } from "./components/rewardItem";
import RewardServices from "../../services/rewardService";
import Cookies from 'js-cookie';
import Whatsapp from "../../assets/whatsapp.svg"
import ModalIndication from "../../components/Indication/modalIndication";
import RewardRequest from "./components/rewardRequest";

const rewardServices = new RewardServices();



export default function SettingsScreen() {
    const [currentTab, setCurrentTab] = useState(0);
    const [rewards, setRewards] = useState([]);
    const [rewardsRequest, setRewardsRequest] = useState([]);
    const [createRewardInput, setCreateRewardInput] = useState(false);
    const [title, setTitle] = useState("");
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [expandDetail, setExpandDetail] = useState(false);


    const createReward = async () => {

        try {

            const userId = Cookies.get('userId');

            const res = await rewardServices.CreateReward(userId, title);

            if (res.status) {

                alert("Recompensa criada com sucesso!");
                setTitle("");
                setCreateRewardInput(false)
                fetchData()
            } else {
                alert("Não foi possível criar Recompensa");
            }

        } catch (e) {
            alert("Não foi possível criar Recompensa");
        }

    }

    useEffect(() => {
        document.title = 'Configurações - Horizontes Fibra';
      }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await rewardServices.GetAllRewards();

            const rewardsRequestResponse = await rewardServices.GetAllRewardsRequest();

            console.log(response);


            if (response.status) {
                setRewards(response.rewards)
                if (rewardsRequestResponse.status) {
                    console.log(rewardsRequestResponse.rewardsRequest)
                    setRewardsRequest(rewardsRequestResponse.rewardsRequest);
                }
            }

        } catch (e) {
            console.log("não houve retorno")
        }
    }


    if (window.innerWidth < 600) {
        return (

            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                <Box>
                    <img src="/assets/logo.png" alt="Logo" />
                </Box>

                {/*  TABS - PENDENTES E CONCLUIDOS */}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Stack onClick={() => setCurrentTab(0)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 0 ? "2px solid black" : "none" }}>
                        <Typography>Recompensas</Typography>
                    </Stack>
                    <Stack onClick={() => setCurrentTab(1)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 1 ? "2px solid black" : "none" }}>
                        <Typography>Solicitações</Typography>
                    </Stack>
                </Box>


                {currentTab == 0 && <Box sx={{ maxWidth: 550, mt: 5, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>


                    {rewards.map(e => {
                        return <RewardItem key={e.id} id={e.id} title={e.title} currentStatus={e.status} reloadState={fetchData} />
                    })}



                    {createRewardInput && <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 260 }}>

                        <Checkbox
                            sx={{
                                width: 50, // largura do checkbox
                                height: 50, // altura do checkbox
                            }}
                        // outras propriedades do checkbox aqui
                        />

                        <input type="text" style={{
                            outline: "none",
                            border: "none",
                            borderBottom: "1px solid black",
                            width: 125
                        }} onChange={(e) => setTitle(e.target.value)} value={title} />

                        <Check onClick={createReward} />

                        <Close onClick={() => { setCreateRewardInput(false) }} />

                    </Stack>}


                    <Stack sx={{ mt: 5, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>

                        {!createRewardInput && <Typography onClick={() => setCreateRewardInput(true)} sx={{ color: "#1EA6E0", cursor: "pointer" }}>Adicionar recompensa</Typography>}

                    </Stack>



                </Box>}


                {currentTab == 1 && <Box sx={{ maxWidth: 550, mt: 5, mb: 50, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>


                    {rewardsRequest.map((e) => {
                        return (
                            <Stack sx={{ mt: 3, padding: "20px 0px 20px 0px", display: "flex", flexDirection: "column", width: "324px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center" }}>
                                <Stack sx={{ display: "flex", flexDirection: "column", width: "324px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center" }}>
                                    <Stack sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: 12, }}>Maison</Typography>
                                        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>Pix</Typography>
                                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                                            <img src={Whatsapp} width={16} />
                                            <Typography sx={{ ml: 1, fontSize: 12, }}>11962396219</Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>

                            </Stack>
                        )
                    })}



                </Box>
                }


                <Box sx={{ borderRadius: 20, position: "fixed", bottom: '100px', right: "40%", height: "66px", width: "304px", backgroundColor: "rgba(0,0,0,0.7)", display: "flex", justifyContent: "space-around", alignItems: "center" }}>

                    <NavLink to={"/home"} style={{ display: "flex", flexDirection: "column", textDecoration: "none" }}>
                        <img src={Home} height={25} />
                        <Typography sx={{ color: "white", fontSize: 12, color: "#A6A6A6" }}>Home</Typography>
                    </NavLink>

                    <NavLink to={"/"} style={{ display: "flex", flexDirection: "column", textDecoration: "none" }}>
                        <img src={AddIcon} height={40} />
                    </NavLink>

                    <NavLink to={"/configurações"} style={{ display: "flex", flexDirection: "column", textDecoration: "none" }}>
                        <img src={Settings} height={25} />
                        <Typography sx={{ color: "white", fontSize: 12, color: "#A6A6A6" }}>Conf.</Typography>
                    </NavLink>




                </Box>

            </Box>

        );
    } else {
        return (


            <Box sx={{ display: "flex", flexDirection: "row", mb: 30 }}>

                <Box sx={{ zIndex: 99, position: "fixed", display: "flex", height: "100vh", width: 100, backgroundColor: "#EEEEEE", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>

                    <Box>
                        <NavLink to={"/home"} style={{ display: "flex", flexDirection: "column", marginTop: 20, mb: 1, alignItems: "center", textDecoration: "none" }}>
                            <Home sx={{ fontSize: 28, color: "black" }} />
                            <Typography sx={{ color: "black", fontSize: 12 }}>Home</Typography>
                        </NavLink>

                        <NavLink to={"/configurações"} style={{ display: "flex", marginTop: 20, flexDirection: "column", textDecoration: "none", alignItems: "center" }}>
                            <Settings sx={{ color: "black", fontSize: 28 }} />
                            <Typography sx={{ fontSize: 12, color: "black" }}>Config.</Typography>
                        </NavLink>
                        <Stack onClick={() => { Cookies.remove('userId'); Cookies.remove('role'); Cookies.remove('jwtToken'); window.location.reload() }} style={{ display: "flex", marginTop: 20, flexDirection: "column", textDecoration: "none", alignItems: "center" }}>
                            <Logout sx={{ color: "black", fontSize: 28 }} />
                            <Typography sx={{ fontSize: 12, color: "black" }}>Logout.</Typography>
                        </Stack>
                    </Box>

                    <Stack onClick={() => setShowAddUserModal(true)} sx={{ display: "flex", flexDirection: "column", marginBottom: 5 }}>
                        <img src={AddIcon} height={40} />
                    </Stack>

                </Box>

                <Box sx={{ display: "flex", height: "100vh", alignItems: "flex-start", flexDirection: "column", marginLeft: 20 }}>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src="/assets/logo.png" alt="Logo" style={{ marginRight: 30 }} />

                        <div style={{ width: 400, height: 32, border: "1px solid black", borderRadius: 15, paddingLeft: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input type="text" style={{ width: 355, height: 30, border: "none", outline: "none", backgroundColor: "transparent" }} />
                            <Search />
                        </div>


                    </Box>

                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>


                        {/*  Itens */}

                        {currentTab == 0 && <Box sx={{ maxWidth: 550, mt: 5, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start" }}>


                            {rewards.map(e => {
                                return <RewardItem key={e.id} id={e.id} title={e.title} currentStatus={e.status} reloadState={fetchData} />
                            })}



                            {createRewardInput && <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: 260 }}>

                                <Checkbox
                                    sx={{
                                        width: 50, // largura do checkbox
                                        height: 50, // altura do checkbox
                                    }}
                                // outras propriedades do checkbox aqui
                                />

                                <input type="text" style={{
                                    outline: "none",
                                    border: "none",
                                    borderBottom: "1px solid black",
                                    width: 125
                                }} onChange={(e) => setTitle(e.target.value)} value={title} />

                                <Check onClick={createReward} />

                                <Close onClick={() => { setCreateRewardInput(false) }} />

                            </Stack>}


                            <Stack sx={{ mt: 5, height: 50, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column" }}>

                                {!createRewardInput && <Typography onClick={() => setCreateRewardInput(true)} sx={{ color: "#1EA6E0", cursor: "pointer" }}>Adicionar recompensa</Typography>}

                            </Stack>



                        </Box>}


                        {currentTab == 1 &&



                            <Box sx={{ maxWidth: 1000, mt: 5, mb: 50, display: "flex", flexDirection: "column"}}>

                                <Box sx={{ maxWidth: 1000, mt: 5, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>


                                    {rewardsRequest.filter(e => e.rewardStatus != 1).map((e, index) => {
                                        return (
                                            <RewardRequest key={index} rewardStatus={e.rewardStatus} id={e.id} name={e.name} phone={e.indicatorPhone} status={e.status} email={e.email} reward={e.title} />
                                        )
                                    })}

                                </Box>


                                <Box>
                                    <Typography>Recompensados</Typography>
                                    {rewardsRequest.filter(e => e.rewardStatus == 1).map((e, index) => {
                                        return (
                                            <RewardRequest key={index} rewardStatus={e.rewardStatus} id={e.id} name={e.name} phone={e.indicatorPhone} status={e.status} email={e.email} reward={e.title} />
                                        )
                                    })}
                                </Box>

                            </Box>


                        }



                    </Box>

                    {
                        showAddUserModal &&
                        <ModalIndication handleModal={() => setShowAddUserModal(false)} />
                    }

                    {/*  TABS - PENDENTES E CONCLUIDOS */}
                    <Box sx={{ display: "flex", flexDirection: "row", position: "fixed", bottom: 30, width: "100%", justifyContent: "flex-start" }}>
                        <Stack onClick={() => setCurrentTab(0)} sx={{ cursor: "pointer", p: 2, borderTop: currentTab === 0 ? "2px solid black" : "none" }}>
                            <Typography>Recompensas</Typography>
                        </Stack>
                        <Stack onClick={() => setCurrentTab(1)} sx={{ cursor: "pointer", p: 2, borderTop: currentTab === 1 ? "2px solid black" : "none" }}>
                            <Typography>Solicitações</Typography>
                        </Stack>
                    </Box>

                </Box>




            </Box>


        );
    }


}