import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
import InputMask from 'react-input-mask';

import UserServices from "../../services/userServices";


const userService = new UserServices();

const storeTokenInCookie = (token) => {
    Cookies.set('jwtToken', token, {
        expires: 7, // Token expira em 7 dias
        secure: true, // Cookie só será enviado em conexões HTTPS
        sameSite: 'Strict' // Cookie só será enviado no mesmo site
    });
};


export default function RegisterScreen() {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        document.title = 'Registre-se - Horizontes Fibra';
    }, []);

    const Register = async () => {

        try {

            if (confirmPassword == password) {
                const plainPhone = phone.replace(/\D/g, '');

                const res = await userService.Register(email, plainPhone, password);

                if (res.status) {
                    console.log("criado")
                    console.log(res.token);
                    storeTokenInCookie(res.token);
                    window.location.href = "/home";
                } else {
                    alert("Não foi possível criar usuario");
                }
            } else {
                alert("Senhas diferentes!");
            }

        } catch (e) {
            alert("Não foi possível criar usuario");
        }

    }



    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >

            <Box>
                <Box sx={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src="/assets/logo.png" alt="Logo" />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
                        Fazer Login
                    </Typography>
                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input

                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}

                            style={{
                                height: "53px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }} type="text" placeholder="Email" />
                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <InputMask
                            mask="(99) 99999-9999"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        >
                            {(inputProps) => (
                                <input
                                    {...inputProps}
                                    style={{
                                        height: "53px",
                                        width: "280px",
                                        border: "none",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        fontSize: 16
                                    }}
                                    type="text"
                                    placeholder="(00) 00000-0000"
                                />
                            )}
                        </InputMask>
                    </div>


                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                            style={{
                                height: "53px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }} type={showPassword ? 'text' : 'password'} placeholder="Senha" />
                        <button
                            onClick={toggleShowPassword}
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            value={confirmPassword}
                            style={{
                                height: "53px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }} type={showConfirmPassword ? 'text' : 'password'} placeholder="Confirmar senha" />
                        <button
                            onClick={toggleShowConfirmPassword}
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                    </div>

                    <Stack onClick={() => Register()} sx={{ display: "flex", width: "100%", backgroundColor: "#000000", height: 35, justifyContent: "center", alignItems: "center", borderRadius: 2, mt: 2 }}>
                        <Typography sx={{ fontSize: 12, color: "white" }}>Continuar</Typography>
                    </Stack>

                    <NavLink to={"/login"} style={{ textDecoration: "none", display: "flex", width: "100%", border: "1px solid black", backgroundColor: "#FFF", height: 30, justifyContent: "center", alignItems: "center", borderRadius: 8, marginTop: 10 }}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>Logar</Typography>
                    </NavLink>
                </Box>

            </Box>


        </Box>
    );
}
