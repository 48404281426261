import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import SearchIcon from "../../assets/search.svg"
import Whatsapp from "../../assets/whatsapp.svg"
import { Close, Home, Logout } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
//import { ReactComponent as Home } from "../../assets/home.svg"
//import Settings from "../../assets/settingsIcon.svg"
import AddIcon from "../../assets/addIcon.svg"
import { NavLink } from "react-router-dom";
import { Search } from "@mui/icons-material";
import Report from "../../components/Report/Report";
import styles from './style.module.css'
import IndicationServices from "../../services/indicationServices";
import ModalIndication from "../../components/Indication/modalIndication";
import IndicationItem from "../../components/Indication/indicationItem";
import Cookies from 'js-cookie';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import MyChart from "../../components/Report/chart";

// Registra os componentes do Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const indicationService = new IndicationServices();



export default function HomeScreen({ isAdmin }) {
    const [currentTab, setCurrentTab] = useState(0);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [indications, setIndications] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [showCloseSearch, setShowCloseSearch] = useState(false);
    const [chartData, setChartData] = useState([]);


    useEffect(() => {
        setIndications(indications.filter((e) => e.name.startsWith(searchText)));
    }, [searchText]);

    useEffect(() => {
        document.title = 'Home - Horizontes Fibra';
    }, []);


    useEffect(() => {

        console.log(chartData)

    }, [chartData])


    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Aberto',
                data: [30, 30, 30, 30, 30, 30, 30],
                fill: false,
                borderColor: '#F2A735',
                tension: 0.1,
            },
            {
                label: 'Em negociação',
                data: [20, 20, 20, 20, 20, 20, 20],
                fill: false,
                borderColor: '#F4159B',
                tension: 0.1,
            },
            {
                label: 'Ganhou',
                data: [40, 40, 40, 40, 40, 40, 40],
                fill: false,
                borderColor: '#27A468',
                tension: 0.1,
            },
            {
                label: 'Perdeu',
                data: [50, 50, 50, 50, 50, 50, 50],
                fill: false,
                borderColor: 'red',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,  // Esta linha oculta a legenda
            },
            title: {
                display: true,
                text: 'Relatório',
            },
        },
    };

    useEffect(() => {
        console.log();
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const userId = Cookies.get('userId');
            const role = Cookies.get('role');
            let res;
            let chartData;
            if (role == 0) {
                res = await indicationService.GetAllIndicationsById(userId);
            } else if (role == 1) {
                res = await indicationService.GetAllIndications();
                chartData = await indicationService.getChartData();

                if (chartData.status) {
                    setChartData(chartData.data);
                }

            }



            setSearchText("");

            if (res.status) {
                setIndications(res.indications);
            }

        } catch (e) {
            console.log("não houve retorno")
        }
    }



    if (window.innerWidth < 600) {
        return (

            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mb: 50 }}>

                <Box>
                    <img src="/assets/logo.png" alt="Logo" />
                </Box>

                <Box sx={{ width: 340 }}>
                    {/*  TABS - PENDENTES E CONCLUIDOS */}
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Stack onClick={() => setCurrentTab(0)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 0 ? "2px solid black" : "none" }}>
                            <Typography>Pendentes</Typography>
                        </Stack>
                        <Stack onClick={() => setCurrentTab(1)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 1 ? "2px solid black" : "none" }}>
                            <Typography>Concluídos</Typography>
                        </Stack>
                        {isAdmin && <Stack onClick={() => setCurrentTab(2)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 2 ? "2px solid black" : "none" }}>
                            <Typography>Relatórios</Typography>
                        </Stack>}
                    </Box>
                    {/*  Search */}
                    <Box>
                        <div
                            style={{
                                marginTop: 30,
                                height: "50px",
                                width: "324px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "#F2F4F7",
                                borderRadius: '8px',
                                fontSize: 22,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}
                        >
                            <input style={{
                                height: "50px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }} type="text" placeholder="Pesquisar" value={searchText} onChange={(e) => { setSearchText(e.target.value); setShowCloseSearch(true) }} />
                            <div style={{ borderRadius: "8px", backgroundColor: "black", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                    !showCloseSearch &&
                                    <img src={SearchIcon} alt="" />
                                }
                                {
                                    showCloseSearch &&
                                    <Close sx={{ color: "white" }} onClick={() => { fetchData(); setShowCloseSearch(false) }} />
                                }
                            </div>



                        </div>

                    </Box>
                    {/*  Itens */}

                    {
                        currentTab == 0 &&

                        indications.filter((e) => e.status == 0 || e.status == 1).map(e => {
                            return <IndicationItem key={e.id} reFetch={fetchData} indicationData={e} admin={isAdmin} />
                        })



                    }


                    {
                        currentTab == 1 &&
                        indications.filter((e) => e.status == 2).map(e => {
                            return <IndicationItem key={e.id} reFetch={fetchData} indicationData={e} admin={isAdmin} />
                        })
                    }


                    {
                        currentTab == 2 &&
                        <Box sx={{ mt: 5 }}>
                            Todos: {indications.length}
                            <div className={styles.problems}>
                                <div className={styles.grid}>

                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ marginTop: 15 }}>
                                            <Report color={2} percentage={`${parseInt(((indications.filter(e => e.status == 0).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 0).length} content={"Aberto"} />
                                        </div>
                                        <div style={{ marginTop: 15 }}>
                                            <Report color={4} percentage={`${parseInt(((indications.filter(e => e.status == 1).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 1).length} content={"Andamento"} />
                                        </div>
                                        <div style={{ marginTop: 15 }}>
                                            <Report color={1} percentage={`${parseInt(((indications.filter(e => e.status == 2).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 2).length} content={"Concluído"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    }






                </Box>

                <Box sx={{ position: "fixed", bottom: "40px", borderRadius: 20, height: "66px", width: "304px", backgroundColor: "rgba(0,0,0,0.7)", display: "flex", justifyContent: "space-around", alignItems: "center" }}>

                    {
                        isAdmin && <Stack sx={{ display: "flex", flexDirection: "column" }}>
                            <Home />
                            <Typography sx={{ color: "white", fontSize: 12 }}>Home</Typography>
                        </Stack>
                    }


                    <Stack onClick={() => setShowAddUserModal(true)} sx={{ display: "flex", flexDirection: "column" }}>
                        <img src={AddIcon} height={40} />
                    </Stack>

                    {
                        isAdmin && <NavLink to={"/configurações"} style={{ display: "flex", flexDirection: "column", textDecoration: "none" }}>
                            <Settings sx={{ color: "black" }} />
                            <Typography sx={{ color: "white", fontSize: 12 }}>Conf.</Typography>
                        </NavLink>
                    }




                </Box>


                {
                    showAddUserModal &&
                    <ModalIndication handleModal={() => setShowAddUserModal(false)} />
                }




            </Box>
        )
    } else {
        return (

            <Box sx={{ display: "flex", flexDirection: "row", mb: 30 }}>

                <Box sx={{ zIndex: 99, position: "fixed", display: "flex", height: "100vh", width: 100, backgroundColor: "#EEEEEE", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>

                    <Box>
                        <Stack sx={{ display: "flex", flexDirection: "column", marginTop: 3, mb: 1, alignItems: "center" }}>
                            <Home sx={{ fontSize: 28 }} />
                            <Typography sx={{ color: "black", fontSize: 12 }}>Home</Typography>
                        </Stack>

                        {isAdmin && <NavLink to={"/configurações"} style={{ display: "flex", marginTop: 20, flexDirection: "column", textDecoration: "none", alignItems: "center" }}>
                            <Settings sx={{ color: "black", fontSize: 28 }} />
                            <Typography sx={{ fontSize: 12, color: "black" }}>Config.</Typography>
                        </NavLink>}
                        <Stack onClick={() => { Cookies.remove('userId'); Cookies.remove('role'); Cookies.remove('jwtToken'); window.location.reload() }} style={{ display: "flex", marginTop: 20, flexDirection: "column", textDecoration: "none", alignItems: "center" }}>
                            <Logout sx={{ color: "black", fontSize: 28 }} />
                            <Typography sx={{ fontSize: 12, color: "black" }}>Logout.</Typography>
                        </Stack>
                    </Box>

                    <Stack onClick={() => setShowAddUserModal(true)} sx={{ display: "flex", flexDirection: "column", marginBottom: 5 }}>
                        <img src={AddIcon} height={40} />
                    </Stack>

                </Box>

                <Box sx={{ display: "flex", height: "100vh", alignItems: "flex-start", flexDirection: "column", marginLeft: 20 }}>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src="/assets/logo.png" alt="Logo" style={{ marginRight: 30 }} />

                        <div style={{ width: 400, height: 32, border: "1px solid black", borderRadius: 15, paddingLeft: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input placeholder="Pesquisar por nome" value={searchText} onChange={(e) => { setSearchText(e.target.value); setShowCloseSearch(true) }} type="text" style={{ width: 355, height: 30, border: "none", outline: "none", backgroundColor: "transparent" }} />
                            <Search />
                        </div>
                        {
                            showCloseSearch && <Close onClick={() => { fetchData(); setShowCloseSearch(false) }} />
                        }



                    </Box>

                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>


                        {/*  Itens */}

                        {
                            currentTab == 0 &&

                            indications.filter((e) => e.status == 0 || e.status == 1).map(e => {
                                return (<IndicationItem key={e.id} reFetch={fetchData} indicationData={e} admin={isAdmin} />)
                            })



                        }


                        {
                            currentTab == 1 &&
                            indications.filter((e) => e.status == 2).map(e => {
                                return <IndicationItem key={e.id} reFetch={fetchData} indicationData={e} admin={isAdmin} />
                            })
                        }


                        {
                            currentTab == 2 &&
                            <Box sx={{ mt: 5 }}>
                                Todos: {indications.length}
                                <div className={styles.problems}>
                                    <div className={styles.grid}>

                                        <Report color={2} percentage={`${parseInt(((indications.filter(e => e.status == 0).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 0).length} content={"Aberto"} />
                                        <Report color={4} percentage={`${parseInt(((indications.filter(e => e.status == 1).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 1).length} content={"Negociação"} />
                                        <Report color={1} percentage={`${parseInt(((indications.filter(e => e.status == 2).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 2).length} content={"Ganhou"} />
                                        <Report color={5} percentage={`${parseInt(((indications.filter(e => e.status == 3).length) / indications.length) * 100)}%`} number={indications.filter(e => e.status == 3).length} content={"Perdeu"} />
                                    </div>
                                </div>
                                <Box sx={{ mt: 8, height: 500 }}>
                                    <MyChart data={chartData} />
                                </Box>
                            </Box>
                        }






                    </Box>

                    {
                        showAddUserModal &&
                        <ModalIndication handleModal={() => { setShowAddUserModal(false); fetchData() }} />
                    }

                    {/*  TABS - PENDENTES E CONCLUIDOS */}
                    <Box sx={{ display: "flex", flexDirection: "row", position: "fixed", bottom: 30, width: "100%", justifyContent: "flex-start" }}>
                        <Stack onClick={() => setCurrentTab(0)} sx={{ cursor: "pointer", p: 2, borderTop: currentTab === 0 ? "2px solid black" : "none" }}>
                            <Typography>Pendentes</Typography>
                        </Stack>
                        <Stack onClick={() => setCurrentTab(1)} sx={{ cursor: "pointer", p: 2, borderTop: currentTab === 1 ? "2px solid black" : "none" }}>
                            <Typography>Concluídos</Typography>
                        </Stack>
                        {isAdmin && <Stack onClick={() => setCurrentTab(2)} sx={{ cursor: "pointer", p: 2, borderBottom: currentTab === 2 ? "2px solid black" : "none" }}>
                            <Typography>Relatórios</Typography>
                        </Stack>}
                    </Box>

                </Box>




            </Box>
        )
    }


}