import React, {useState} from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import Cookies from 'js-cookie';
import IndicationServices from "../../services/indicationServices";

const indicationService = new IndicationServices();

export default function ModalIndication({handleModal}){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");


    const createIndication = async ()=>{

        try{

            const userId = Cookies.get('userId');

            const res = await indicationService.CreateIndication(userId, name, email, phone);

            if(res.status){

                alert("Indicação criada com sucesso!");
                handleModal();
            }else{
                alert("Não foi possível criar usuario");
            }

        }catch(e){
            alert("Não foi possível criar usuario");
        }

    }



    return (
        <Box sx={{
            top: 0,
            right: 0,
            position: "fixed",
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            backdropFilter: "blur(2px)",
            zIndex: 1000,
        }}>

            <Box onClick={handleModal} sx={{ position: "absolute", width: "100vw", height: "100vh" }}>

            </Box>

            <Box sx={{ zIndex: 2, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.5)", display: "flex", height: 300, width: 300, backgroundColor: "#FAFAFA", justifyContent: "center", alignItems: "center", borderRadius: 5, flexDirection: "column" }}>

                <Typography>Adicionar usuário</Typography>

                <Box>
                    <div
                        style={{
                            marginTop: 10,
                            height: "50px",
                            width: "250px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "white",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                        value={name}
                        onChange={(e)=>{
                            setName(e.target.value)
                        }}


                        style={{
                            height: "50px",
                            width: "280px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            fontSize: 16
                        }} type="text" placeholder="Nome" />

                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            height: "50px",
                            width: "250px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "white",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                        
                        value={email}
                        onChange={(e)=>{
                            setEmail(e.target.value)
                        }}

                        style={{
                            height: "50px",
                            width: "280px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            fontSize: 16
                        }} type="text" placeholder="Email" />

                    </div>

                    <div
                        style={{
                            marginTop: 10,
                            height: "50px",
                            width: "250px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "white",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                        
                        value={phone}
                        onChange={(e)=>{
                            setPhone(e.target.value)
                        }}

                        style={{
                            height: "50px",
                            width: "280px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            fontSize: 16
                        }} type="text" placeholder="Telefone" />

                    </div>

                </Box>
                <button onClick={createIndication} style={{ outline: "none", border: "none", marginTop: "20px", width: "200px", height: "25px", padding: "5px", borderRadius: "5px", backgroundColor: "white" }}>Cadastrar</button>

            </Box>

        </Box>
    )

}