import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';

import UserServices from "../../services/userServices";


const userService = new UserServices();

const storeTokenInCookie = (token) => {
    Cookies.set('jwtToken', token, {
        expires: 7, // Token expira em 7 dias
        secure: true, // Cookie só será enviado em conexões HTTPS
        sameSite: 'Strict' // Cookie só será enviado no mesmo site
    });
};

const storeUserIdInCookie = (id) => {
    Cookies.set('userId', id, {
        expires: 7, // Token expira em 7 dias
        secure: true, // Cookie só será enviado em conexões HTTPS
        sameSite: 'Strict' // Cookie só será enviado no mesmo site
    });
};

const storeRoleInCookie = (role) => {
    Cookies.set('role', role, {
        expires: 7, // Token expira em 7 dias
        secure: true, // Cookie só será enviado em conexões HTTPS
        sameSite: 'Strict' // Cookie só será enviado no mesmo site
    });
};

export default function LoginScreen() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        document.title = 'Login - Horizontes Fibra';
    }, []);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };

    const Login = async () => {

        try {

            const res = await userService.Login(email, password);

            if (res.status) {
                storeTokenInCookie(res.token);
                storeUserIdInCookie(res.userId);
                storeRoleInCookie(res.role);
                window.location.href = "/home";
            } else {
                alert("Não foi possível logar");
            }

        } catch (e) {
            alert("Não foi possível logar");
        }

    }




    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >

            <Box>
                <Box sx={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src="/assets/logo.png" alt="Logo" />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
                        Fazer Login
                    </Typography>
                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}
                            style={{
                                height: "53px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }} type="text" placeholder="Email" />
                    </div>


                    <div
                        style={{
                            marginTop: 10,
                            height: "54px",
                            width: "324px",
                            border: "none",
                            outline: "none",
                            backgroundColor: "#F2F4F7",
                            borderRadius: '8px',
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <input

                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            style={{
                                height: "53px",
                                width: "280px",
                                border: "none",
                                outline: "none",
                                backgroundColor: "transparent",
                                fontSize: 16
                            }}
                            placeholder="Senha" />
                        <button
                            onClick={toggleShowPassword}
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                    </div>



                    <Stack onClick={() => Login()} sx={{ cursor: "pointer", display: "flex", width: "100%", backgroundColor: "#000000", height: 35, justifyContent: "center", alignItems: "center", borderRadius: 2, mt: 2 }}>
                        <Typography sx={{ fontSize: 12, color: "white" }}>Continuar</Typography>
                    </Stack>

                    <NavLink to={"/registre-se"} style={{ textDecoration: "none", display: "flex", width: "100%", border: "1px solid black", backgroundColor: "#FFF", height: 30, justifyContent: "center", alignItems: "center", borderRadius: 8, marginTop: 10 }}>
                        <Typography sx={{ fontSize: 12, color: "black" }}>Cadastrar-se</Typography>
                    </NavLink>
                </Box>

            </Box>


        </Box>
    );
}
