import axios from "axios"



class UserServices{



    async Login(email, password){

        try{

            const response = await axios.post("https://cvd.horizontes.top/users/login", {
                email,
                password
            })


            if(response.status == 200){
                return {status: true, token: response.data.token, userId: response.data.id, role: response.data.role}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);

        }

    }



    async Register(email,phone, password){

        
        try{

            const response = await axios.post("https://cvd.horizontes.top/users/create", {
                email,
                phone,
                password
            })

            if(response.status == 200){
                return {status: true, token: response.data.token}
            }else{
                return {status: false}
            }

        }catch(e){

            console.log(e);

        }


    }


}


export default UserServices;