import React from 'react'
import styles from './style.module.css'
import { Typography, Box } from '@mui/material'

const Report = ({percentage, number, content, color}) => {

  const colors = ["#E53761","#27A468", "#F2A735", "#64E537", "#F4159B", "red", "#3788E5", "#E53761", "#27A468", "#F2A735","#E53761","#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735","#E53761","#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735"];

  return (
  <div className={styles.card} style={{padding: 5}}>
    <div style={{display: "flex"}}>
    <div className={styles.innerDiv}>
    <div className={styles.circleProgress} style={{ '--percentage': percentage, "--color": colors[color] }}>
      <div className={styles.circleProgressBackground}></div>
    </div>
    <p className={styles.circleText}>{percentage}</p>
  </div>
      <div className={styles.interCard}>
      <Typography sx={{fontWeight: 700, fontSize: 36, color: "#363565", marginTop: 3, marginLeft: 2}}>{number}</Typography><Typography sx={{color: "#A2A1BC", fontWeight: 300,marginLeft: 2, marginTop: -1}}>{content}</Typography>
    </div>
    </div>
  </div>
  )
}

export default Report