import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Stack, Checkbox, FormControlLabel } from "@mui/material";
import { Delete } from "@mui/icons-material";
import RewardServices from "../../../services/rewardService";

const rewardServices = new RewardServices();

export function RewardItem({ id, title, currentStatus, reloadState }) {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(!!currentStatus);
    }, [currentStatus]);

    const handleChange = async (event) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);

        try {
            const res = await rewardServices.UpdateStatus(id, newChecked);
            if (res.status) {
                alert("Recompensa ativa com sucesso!");
            }
        } catch (e) {
            alert("Não foi possível atualizar status");
        }
    };


    const deleteReward = async ()=>{
        try {
            const res = await rewardServices.DeleteReward(id);
            if (res.status) {
                alert("Recompensa deletada com sucesso!");
                reloadState();
            }
        } catch (e) {
            alert("Não foi possível atualizar status");
        }
    }

    return (
        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", width: 260 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            width: 50,
                            height: 50,
                        }}
                    />
                }
                label={title}
            />

            <Delete onClick={deleteReward} />
        </Stack>
    );
}
