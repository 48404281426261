import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginScreen from './views/LoginScreen/LoginScreen';
import RegisterScreen from './views/RegisterScreen/RegisterScreen';
import HomeScreen from './views/HomeScreen';
import SettingsScreen from './views/SettingsScreen/settingsScreen';
import Cookies from 'js-cookie';
function App() {

  const getToken = () => {
    return Cookies.get('jwtToken');
  };
  
  const isAuthenticated = () => {
    return !!getToken();
  };

  const isAdmin = ()=>{
    const role = Cookies.get('role');
    
    if(role == 0){
      return false
    }else if(role == 1){
      return true
    }
  
  }



  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          <LoginScreen />
        } />

        <Route path="/registre-se" element={
          <RegisterScreen />
        } />

        <Route path="/" element={
          isAuthenticated() ?
          <HomeScreen isAdmin={isAdmin()} />
          :
          <LoginScreen />
        } />

        <Route path="/home" element={
          isAuthenticated() ?
          <HomeScreen isAdmin={isAdmin()} />
          :
          <LoginScreen />
        } />

        <Route path="/configurações" element={
          isAuthenticated() ?
          <SettingsScreen isAdmin={isAdmin()} />
          :
          <LoginScreen />
        } />
      </Routes>
    </Router>
  )
}

export default App;
