import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Stack } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IndicationServices from "../../services/indicationServices";
import Whatsapp from "../../assets/whatsapp.svg"
import RewardServices from "../../services/rewardService";
import Cookies from 'js-cookie';
import axios from "axios";

const rewardServices = new RewardServices();
const indicationService = new IndicationServices();

export default function IndicationItem({ indicationData, reFetch, admin }) {
    const [currentStatus, setCurrentStatus] = useState(0);
    const [currentRewardOption, setCurrentRewardOption] = useState(0);
    const [showRequestReward, setShowRequestReward] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [statusClosed, setStatusClosed] = useState(false);
    const [showDetailTextarea, setShowDetailTextarea] = useState(false);
    const [showIndicationDetail, setShowIndicationDetail] = useState(false);
    const [currentColor, setCurrentColor] = useState("black")
    const [formattedStatus, setFormattedStatus] = useState("");
    const [negStepOne, setNegStepOne] = useState('');
    const [negStepTwo, setNegStepTwo] = useState('');
    const [negStepThree, setNegStepThree] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [nameInput, setNameInput] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');







    useEffect(() => {
        setNegStepOne(indicationData.negStepOne ?? '');
        setNegStepTwo(indicationData.negStepTwo ?? '');
        setNegStepThree(indicationData.negStepThree ?? '');
        setEmail(indicationData.email ?? '');
        setNameInput(indicationData.name ?? '');
        setPhone(indicationData.phone ?? '');
        setCep(indicationData.cep ?? '');
        setRua(indicationData.rua ?? '');
        setBairro(indicationData.bairro ?? '');
        setCidade(indicationData.cidade ?? '');
        setEstado(indicationData.estado ?? '');
        setNumero(indicationData.numero ?? '');
        setComplemento(indicationData.complemento ?? '');

        if (indicationData.status == 0) {
            setFormattedStatus("Aberto")
        } else if (indicationData.status == 1) {
            setFormattedStatus("Em negociação")
        } else if (indicationData.status == 2) {
            setFormattedStatus("Ganhou")
        } else if (indicationData.status == 3) {
            setFormattedStatus("Perdeu")
        }

    }, [indicationData]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const res = await rewardServices.GetAllRewards();

            if (res.status) {
                setRewards(res.rewards.filter(e => e.status == 1));
            }

        } catch (e) {
            console.log("não houve retorno")
        }
    }

    useEffect(() => {
        currentStatus == 2 || currentStatus == 3 ? setShowDetailTextarea(true) : setShowDetailTextarea(false);

        if (currentStatus == 2) {
            setCurrentColor("green");
        } else if (currentStatus == 3) {
            setCurrentColor("red");
        } else {
            setCurrentColor("black");
        }

    }, [currentStatus])

    useEffect(() => {
        if (indicationData.status == 2 || indicationData.status == 3) {
            setStatusClosed(true);
        }
        setCurrentStatus(indicationData.status)
    }, [indicationData])


    const RequestReward = async () => {
        const userId = Cookies.get('userId');
        try {
            const result = await rewardServices.RequestReward(userId, rewards[currentRewardOption].id, indicationData.id,rewards[currentRewardOption].title);

            if (result.status) {
                alert("Recompensa solicitada com sucesso");
                setShowRequestReward(false);
            } else {
                alert("Não foi possível atualizar status");
            }
        } catch (err) {
            alert("Não foi possível atualizar status");
        }

    }


    useEffect(() => {
        
        async function getAddress(){
            try {
                const responseOne = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                const responseData = responseOne.data;
    
                if (responseOne.status == 200) {
                    setRua(responseData.logradouro);
                    setBairro(responseData.bairro);
                    setCidade(responseData.localidade);
                    setEstado(responseData.uf);
                } else {
                    console.log(`Erro na requisição: ${responseOne.status}`);
                }
            } catch (e) {
                console.log(`Erro na requisição: ${e}}`);
            }
        }

        getAddress();

    }, [cep])


    const UpdateStatus = async () => {
        try {
            console.log(indicationData);
            const result = await indicationService.UpdateStatus(indicationData.id, indicationData.indicatorPhone, currentStatus, negStepOne, negStepTwo, negStepThree, email, nameInput, cep, rua, bairro, cidade, estado, numero, complemento);

            if (result.status) {
                alert("Status atualizado com sucesso");
                reFetch();
            } else {
                alert("Não foi possível atualizar status");
            }
        } catch (err) {
            alert("Não foi possível atualizar status");
        }

    }


    return (
        <Box sx={{ m: 3 }}>
            <Stack sx={{ padding: "20px 10px 0px 10px", display: "flex", flexDirection: "column", width: "300px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center" }}>
                <Stack sx={{ display: "flex", flexDirection: "column", width: "300px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center" }}>
                    <Stack sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: 12, }}>{indicationData.name}</Typography>
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                            <img src={Whatsapp} width={16} />
                            <Typography sx={{ ml: 1, fontSize: 12, }}>{indicationData.phone}</Typography>
                        </Stack>
                    </Stack>
                    <Typography sx={{ fontSize: 12, color: currentColor }}>Status: {formattedStatus}</Typography>
                    {
                        indicationData.status == 2 &&
                        <Stack onClick={() => setShowRequestReward(true)} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <Typography sx={{ fontSize: 12, color: "#41BE3F", cursor: "pointer" }}>
                                Solicitar recompensa
                            </Typography>
                        </Stack>
                    }
                </Stack>


                {
                    admin &&
                    <Box sx={{ m: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>

                        {
                            false &&
                            <Stack sx={{ padding: 3 }}>
                                <textarea disabled={indicationData.status == 2 || indicationData.status == 3} placeholder="escreve os detalhes do andamento" type="text" style={{ resize: "none", height: 150, borderRadius: "8px", border: "none", outline: "none" }} />

                                {
                                    indicationData.status !== 2 && indicationData.status !== 3 &&
                                    <button onClick={() => { UpdateStatus() }} style={{ outline: "none", border: "none", fontSize: 12, backgroundColor: "#AFFF89", padding: 5, marginLeft: 5, borderRadius: 5 }}>Atualizar</button>
                                }

                            </Stack>}

                        <button onClick={() => { setShowIndicationDetail(!showIndicationDetail) }} style={{ width: 140, outline: "none", border: "none", fontSize: 12, backgroundColor: "#AFFF89", padding: 5, marginLeft: 5, borderRadius: 5 }}>Detalhes</button>


                    </Box>
                }


            </Stack>

            {
                showRequestReward &&
                <Box sx={{
                    top: 0,
                    right: 0,
                    position: "fixed",
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.0)",
                    backdropFilter: "blur(2px)"
                }}>

                    <Box onClick={() => setShowRequestReward(false)} sx={{
                        top: 0,
                        right: 0,
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                    }}>

                    </Box>

                    <Box sx={{ zIndex: 999, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.5)", display: "flex", height: 200, width: 300, backgroundColor: "#FAFAFA", justifyContent: "space-around", alignItems: "center", borderRadius: 5, flexDirection: "column" }}>

                        <Typography sx={{ fontSize: 20, marginTop: "20px" }}>Solicitar recompensa</Typography>


                        <Box>
                            <select value={currentRewardOption} onChange={(h) => setCurrentRewardOption(h.target.value)} style={{ fontSize: 16, width: "120px", border: "0.5px solid black", borderRadius: "8px", height: 30, outline: "none", marginTop: "10px" }}>
                                {
                                    rewards.map((e, index) => <option key={e.id} value={index}>{e.title}</option>)
                                }
                            </select>
                        </Box>

                        <button onClick={() => RequestReward()} style={{ outline: "none", border: "none", marginTop: "20px", marginBottom: "20px", width: "200px", padding: "8px", borderRadius: "8px", backgroundColor: "#3CE800", color: "white", fontWeight: 600 }}>Solicitar</button>

                    </Box>

                </Box>
            }




            {showIndicationDetail &&
                <Box sx={{
                    zIndex: 999,
                    top: 0,
                    right: 0,
                    position: "fixed",
                    display: "flex",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.0)",
                    backdropFilter: "blur(2px)"
                }}>

                    <Box onClick={() => setShowIndicationDetail(false)} sx={{
                        top: 0,
                        right: 0,
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                    }}>

                    </Box>

                    <Box sx={{ zIndex: 999, p: 3, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.5)", display: "flex", width: 700, maxHeight: "90vh", overflowY: "auto", backgroundColor: "#F1F1F1", alignItems: "flex-start", borderRadius: 5, flexDirection: "column" }}>

                        <Typography sx={{ fontSize: 20, marginBottom: "20px" }}>Detalhes da indicação</Typography>



                        <Typography sx={{ fontSize: 16 }}>Contato</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", mb: 3, justifyContent: "center" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                                <div style={{ margin: 10 }}>
                                    <Typography sx={{ fontSize: 10 }}>Nome</Typography>
                                    <input
                                        type="text"
                                        value={nameInput}
                                        onChange={(e) => setNameInput(e.target.value)}
                                        style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                    />
                                </div>
                                <div style={{ margin: 10 }}>
                                    <Typography sx={{ fontSize: 10 }}>Email</Typography>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                    />
                                </div>
                                <div style={{ margin: 10 }}>
                                    <Typography sx={{ fontSize: 10 }}>Telefone</Typography>
                                    <input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        type="text"
                                        style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                    />
                                </div>
                            </Box>
                        </Box>
                        <Typography sx={{ fontSize: 16 }}>Endereço</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>CEP</Typography>
                                <input
                                    type="text"
                                    value={cep}
                                    onChange={(e) => setCep(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Rua</Typography>
                                <input
                                    type="text"
                                    value={rua}
                                    onChange={(e) => setRua(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Bairro</Typography>
                                <input
                                    type="text"
                                    value={bairro}
                                    onChange={(e) => setBairro(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Cidade</Typography>
                                <input
                                    type="text"
                                    value={cidade}
                                    onChange={(e) => setCidade(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Estado</Typography>
                                <input
                                    type="text"
                                    value={estado}
                                    onChange={(e) => setEstado(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Número</Typography>
                                <input
                                    type="text"
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                            <div style={{ margin: 10 }}>
                                <Typography sx={{ fontSize: 10 }}>Complemento</Typography>
                                <input
                                    type="text"
                                    value={complemento}
                                    onChange={(e) => setComplemento(e.target.value)}
                                    style={{ padding: 10, outline: "none", border: "none", backgroundColor: "white", borderRadius: 5 }}
                                />
                            </div>
                        </Box>
                        <Typography sx={{ fontSize: 16, mt: 5 }}>Negociação</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            <Box>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Stack>
                                        <Typography sx={{ fontSize: 12 }}>1º passo</Typography>
                                        <textarea
                                            value={negStepOne}
                                            onChange={(e) => setNegStepOne(e.target.value)}
                                            style={{ borderRadius: 8, border: "none", outline: "none", width: 200, height: 150, resize: "none", margin: 5 }}
                                        />
                                    </Stack>
                                    <ChevronRightIcon sx={{ color: "green" }} />
                                    <Stack>
                                        <Typography sx={{ fontSize: 12 }}>2º passo</Typography>
                                        <textarea
                                            disabled={negStepOne == "" || negStepOne == null}
                                            value={negStepTwo}
                                            onChange={(e) => setNegStepTwo(e.target.value)}
                                            style={{ borderRadius: 8, border: "none", outline: "none", width: 200, height: 150, resize: "none", margin: 5 }}
                                        />
                                    </Stack>
                                    <ChevronRightIcon sx={{ color: "green" }} />
                                    <Stack>
                                        <Typography sx={{ fontSize: 12 }}>3º passo</Typography>
                                        <textarea
                                            disabled={negStepTwo == "" || negStepTwo == null}
                                            value={negStepThree}
                                            onChange={(e) => setNegStepThree(e.target.value)}
                                            style={{ borderRadius: 8, border: "none", outline: "none", width: 200, height: 150, resize: "none", margin: 5 }}
                                        />
                                    </Stack>
                                </div>
                            </Box>
                            <Stack sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
                                Status:
                                <select
                                    disabled={statusClosed}
                                    onChange={(e) => setCurrentStatus(parseInt(e.target.value))}
                                    value={currentStatus}
                                    style={{ fontSize: 12, width: "100px", border: "none", borderRadius: "8px", height: 23, outline: "none", marginLeft: "5px", color: currentColor }}>
                                    <option value={0} style={{ textAlign: "center", color: "black" }}>ABERTO</option>
                                    <option value={1} style={{ textAlign: "center", color: "black" }}>NEGOCIAÇÃO</option>
                                    <option value={2} style={{ textAlign: "center", color: "green" }}>GANHOU</option>
                                    <option value={3} style={{ textAlign: "center", color: "red" }}>PERDEU</option>
                                </select>
                                <button
                                    onClick={UpdateStatus}
                                    style={{ outline: "none", border: "none", fontSize: 12, backgroundColor: "#AFFF89", padding: 5, marginLeft: 5, borderRadius: 5 }}>
                                    Atualizar
                                </button>
                            </Stack>
                        </Box>



                    </Box>

                </Box>
            }
        </Box>
    )

}