import React, { useState } from "react";
import { Stack, Typography, Box, Collapse } from "@mui/material";
import { Check, Close, ExpandMore } from "@mui/icons-material";
import Whatsapp from "../../../assets/whatsapp.svg"
import RewardServices from "../../../services/rewardService";

const rewardServices = new RewardServices();

export default function RewardRequest({ id, rewardStatus, name, phone, status, email, reward }) {

    const [expandDetail, setExpandDetail] = useState(false);

    const announceReward = async () => {
        try {

            const response = await rewardServices.AnnouncementReward(phone, id);

            if (response.status) {
                alert("Recompensa anunciada com sucesso!");
            } else {
                alert("Não foi possível anunciar recompensa");
            }
        } catch (err) {
            alert("Não foi possível anunciar recompensa");
        }
    }

    return (
        <Stack sx={{ m: 3, padding: "10px 0px 0px 0px", display: "flex", flexDirection: "column", width: "324px", height: expandDetail ? "300px" : "130px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center" }}>
            <Stack sx={{ display: "flex", flexDirection: "column", width: "324px", backgroundColor: "#F8F8FB", borderRadius: "8px", justifyContent: "center", alignItems: "center" }}>
                <Typography>{email}</Typography>


                <Stack sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <img src={Whatsapp} width={16} />
                        <Typography sx={{ ml: 1, fontSize: 12, }}>{phone}</Typography>
                    </Stack>

                    <Typography sx={{ fontSize: 12, }}>indicação: {name}</Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 600 }}>{reward}</Typography>

                </Stack>


            </Stack>
            <Box sx={{ m: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <select disabled={true} value={status} style={{ fontSize: 12, width: "100px", border: "none", borderRadius: "8px", height: 23, outline: "none", marginLeft: "20px" }}>
                        <option value={0} style={{ textAlign: "center" }}>ABERTO</option>
                        <option value={1} style={{ textAlign: "center" }}>NEGOCIAÇÃO</option>
                        <option value={2} style={{ textAlign: "center" }}>GANHOU</option>
                        <option value={3} style={{ textAlign: "center" }}>PERDEU</option>
                    </select>

                    {
                        rewardStatus != 1 &&
                    <Typography onClick={() => { announceReward() }} sx={{ fontSize: 12, color: "green", ml: 3, cursor: "pointer" }}>Anunciar recompensa</Typography>
                    }
                </Box>

            </Box>
        </Stack>
    )
}